<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Ingatlan Hozzáadása</h5>
                            </div> 
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'RealEstates List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div id="wrapper" class="d-flex">
                            <div class="w-25" :style="`
                                        background-image: url(${getImage});
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        background-position: center;
                                        height: 300px;
                                        
                                        `">
                            </div>
                            <div class="w-75">
                                <div class="row p-3">
                                    <div class="row p-3">
                                        <div class="col-5">
                                            <material-input placeholder="Név" id="name" v-model:value="realestate.name"
                                                name="name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.name"></validation-error>
                                        </div>

                                        <div class="col-5">
                                            <material-input placeholder="Helyrajzi Szám" id="lot_number" v-model:value="realestate.lot_number"
                                                name="lot_number" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Cím adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-countries" placeholder="Ország" v-model="realestate.country_code" name="choices-countries" class="form-control">
                                                <option value="">Válassz!</option>
                                                <option v-for="country in getcountryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="zip" v-model:value="realestate.zip" placeholder="Irányítószám"
                                                name="zip" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.zip"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="city" v-model:value="realestate.city" placeholder="Város"
                                                name="city" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.city"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="address" v-model:value="realestate.address" placeholder="Cím"
                                                name="address" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.address"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Tulajdonosi adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-owners" v-model.number="realestate.owner_id" name="choices-owners" class="form-control">
                                                <option value="">Válassz tulajdonost!</option>
                                                <option v-for="owner in getownerList" :key="owner.id" :value="owner.id" v-html="owner.name"></option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="ownership" v-model:value="realestate.ownership" placeholder="Tulajdoni hányad"
                                                name="ownership" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="deed_size" v-model:value="computedDeedSize" placeholder="Tulajdoni lap szerinti alapterület (m2)"
                                            name="deed_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="gross_size" v-model:value="computedGrossSize" placeholder="Ingatlan bruttó alapterülete (m2)"
                                            name="gross_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Egyéb</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <material-input id="building_nr" v-model:value="realestate.building_nr" placeholder="Épületek száma (db)"
                                                name="building_nr" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-sfunctions" v-model.number="realestate.realestate_functions_id" name="choices-sfunctions" class="form-control">
                                                <option value="">Válassz funkciót!</option>
                                                <option v-for="sfunction in getfunctionList" :key="sfunction.id" :value="sfunction.id">{{ sfunction.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-handlings" v-model.number="realestate.realestate_handlings_id" name="choices-handlings" class="form-control">
                                                <option value="">Válassz kezelési formát!</option>
                                                <option v-for="handling in gethandlingList" :key="handling.id" :value="handling.id">{{ handling.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_name" v-model:value="realestate.property_manager_name" placeholder="Ingatlankezelő neve"
                                                name="property_manager_name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone" placeholder="Ingatlankezelő telefonszáma"
                                                name="property_manager_phone" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_email" v-model:value="realestate.property_manager_email" placeholder="Ingatlankezelő e-mail címe"
                                                name="property_manager_email" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                                        </div>
                                    </div>
                                
                            </div>
                            </div>
                        </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'AddRealEstate',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            realestate: {},
            countries: {},
            owners: {},
            sfunctions: {},
            handlings: {},
        }
    },
    computed: {
        getcountryList() {
            return this.$store.getters["countries/getCountriesData"];
        },
        getownerList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getfunctionList() {
            return this.$store.getters["sfunctions/getFunctionsData"];
        },
        gethandlingList() {
            return this.$store.getters["handlings/getHandlingsData"];
        },
        getAreaFloorCoveringsList() {
            return this.$store.getters["coverings/getCoveringsData"];
        },
        getImage() {
            if (!this.profile_image) return require("@/assets/img/haz_rajz.jpg");
        else {
            return this.profile_image;
        }
        },
        computedDeedSize: {
            set(val) {
                this.realestate.deed_size = parseFloat(val);
            }
        },
        computedGrossSize: {
            set(val) {
                this.realestate.gross_size = parseFloat(val);
            }
        },
        computedNetFloorSpace: {
            set(val) {
                this.area.net_floor_space = parseFloat(val);
            }
        },
        computedGrossFloorSpace: {
            set(val) {
                this.area.gross_floor_space = parseFloat(val);
            }
        },
        computedHeight: {
            set(val) {
                this.area.height = parseFloat(val);
            }
        },
    },
    async mounted() {
        await this.$store.dispatch("countries/getCountries");
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("sfunctions/getFunctions");
        await this.$store.dispatch("handlings/getHandlings");
        await this.$store.dispatch("positions/getPositions");
        await this.$store.dispatch("afunctions/getFunctions");
        await this.$store.dispatch("coverings/getCoverings");

        if (document.getElementById("choices-countries")) {
            const countries = document.getElementById("choices-countries");
            this.countries = new Choices(countries);
            this.countries.setChoiceByValue('HU');
            this.realestate.country_code = 'HU';
        }

        if (document.getElementById("choices-owners")) {
            const owners = document.getElementById("choices-owners");
            new Choices(owners);
        }

        if (document.getElementById("choices-sfunctions")) {
            const sfunctions = document.getElementById("choices-sfunctions");
            new Choices(sfunctions);
        }

        if (document.getElementById("choices-handlings")) {
            const handlings = document.getElementById("choices-handlings");
            new Choices(handlings);
        }
    },
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                this.realestate.areas = this.areas;
                await this.$store.dispatch("realestates/addRealEstate", this.realestate);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ingatlan sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "RealEstates List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
    }
}
</script>