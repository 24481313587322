/* eslint-disable no-unused-vars */
import consumptionService from '../services/consumption.service';

const initialState = { consumption: null };

export const consumption = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getConsumption({ commit }, options) {
            const consumption = await consumptionService.getConsumption(options);
            commit('getConsumptionSuccess', consumption);
        },
    },
    mutations: {
        getConsumptionSuccess(state, consumption) {
            state.consumption = consumption;
        }
    },
    getters: {
        getConsumptionData(state) {
            return state.consumption?.data;
        }
    }
}

