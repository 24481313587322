import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import Select from 'primevue/select';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import InputNumber from 'primevue/inputnumber';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import MultiSelect from 'primevue/multiselect';


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: 'system',
            cssLayer: false
        }
    }
});
appInstance.use(ToastService);
appInstance.component('DataTable', DataTable);
appInstance.component('Column', Column);
appInstance.component('InputText', InputText);
appInstance.component('Button', Button);
appInstance.component('Toolbar', Toolbar);
appInstance.component('Dialog', Dialog);
appInstance.component('Select', Select);
appInstance.component('Toast', Toast);
appInstance.component('InputNumber', InputNumber);
appInstance.component('IconField', IconField);
appInstance.component('InputIcon', InputIcon);
appInstance.component('MultiSelect', MultiSelect);
appInstance.mount("#app");
