<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-12">
                                <h5 class="mb-0">Fogyasztásregisztráció</h5>
                            </div>
                            
                            <div class="col-3 mb-4">
                                <label class="form-label mb-0 ms-0">Év/Hónap</label>
                                <VueDatePicker v-model="month" :teleport="true" locale="hu" month-picker />
                            </div>

                            <div class="col-3">
                                    <label class="form-label mb-0 ms-0"> Ingatlan megnevezése</label>
                                    <select id="choices-realestate" v-model.number="consumption.realestate_id" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option value="">Válassz!</option>
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                            </div>

                            <div class="col-4 mb-4">
                                <material-input id="realestate_address" v-model:value="consumption.realestate_address" label="Hely címe"
                                    name="realestate_address" variant="static" disabled></material-input>
                             </div>

                             <div class="col-5 mb-4">
                                    <label class="form-label mb-0 ms-0">Közmű szolgáltatás</label>
                                    <select id="choices-utility_services_id" v-model.number="consumption.utility_services_id" name="choices-utility_services_id" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="sutility in getutilityList" :key="sutility.id" :value="sutility.id">{{ sutility.name }}</option>
                                    </select>
                                </div>

                            <div class="col-2 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click.prevent="startRegistration">
                                    Kezdés
                                </material-button>
                            </div>
                        </div>

                        
                    </div>
                    <!--Card body-->
                    <div class="card-body">

                        <!-- Data -->
                        <div v-if="getConsumptionList !== null">
                            <div v-for="network in getConsumptionList">
                                <div v-if="network.get_meters.length !== 0" class="row d-flex align-items-center pb-2">
                                        <button class="btn btn-primary" style="width: 400px;" type="button" data-bs-toggle="collapse" :data-bs-target="`#${network.pod}`" aria-expanded="false" aria-controls="collapseExample">
                                            POD: {{ network.pod }}
                                        </button>
                                        <div class="collapse" :id="`${network.pod}`">
                                            <div class="card card-body">
                                                <div class="row" v-for="meter in network.get_meters">
                                                    <div class="col-3 mb-4">
                                                        <material-input id="realestate_address" v-model:value="meter.product_number" label="Szériaszám"
                                                        name="realestate_address" variant="static" disabled></material-input>
                                                    </div>
                                                    <div class="col-3 mb-4">
                                                        <label class="form-label mb-0 ms-0">Év/Hónap</label>
                                                        <VueDatePicker v-model="month" :teleport="true" locale="hu" month-picker />
                                                    </div>
                                                    <div class="col-3 mb-4">
                                                        <material-input id="realestate_address" v-model:value="meter.test" label="Érték"
                                                        name="realestate_address" variant="static"></material-input>
                                                    </div>
                                                    <div class="col-2 text-end">
                                                        <material-button class="float-right btn btm-sm"
                                                            @click.prevent="startRegistration"
                                                            :disabled="isDisable(network)"
                                                        >
                                                            Felvitel
                                                        </material-button>
                                                    </div>
                                                    <div class="row" v-for="submeter in meter.get_sub_meters">
                                                        <div class="col-3 mb-4">
                                                            <font-awesome-icon :icon="faArrowsTurnRight" />
                                                        </div>
                                                        <div class="col-3 mb-4">
                                                            <material-input id="realestate_address" v-model:value="submeter.product_number" label="Szériaszám"
                                                            name="realestate_address" variant="static" disabled></material-input>
                                                        </div>
                                                        <div class="col-3 mb-4">
                                                        <material-input id="realestate_address" v-model:value="submeter.test" label="Érték"
                                                            name="realestate_address" variant="static"></material-input>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import { CButton, CCollapse, CCard, CCardBody } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowsTurnRight
} from "@fortawesome/free-solid-svg-icons";

export default {
    name: 'Consumption',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        CButton,
        CCollapse,
        CCard,
        CCardBody,
        FontAwesomeIcon,
    },
    mixins: [formMixin],
    setup() {
        const visible = ref(false);
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month,
            visible,
        }
    },
    data() {
        return {
            consumption: {},
            dataConsumption: [],
            date: null,
            dataTable: null,
            consumptionList: null,
            faArrowsTurnRight
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getConsumptionList() {
            return this.$store.getters["consumption/getConsumptionData"];
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("utilities/getUtilities");

        if (document.getElementById("choices-realestate")) {
            const realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            if (this.realestate.getValue(true) != undefined){
                this.currentRealEstate = this.getRealEstateList.filter(value => {
                    return value.id == this.realestate.getValue(true)
                });
                await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(this.realestate.getValue(true) ? { realestate_id: String(this.realestate.getValue(true)) } : {}),
                    },
                });
                document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
            }
        }

        if (document.getElementById("choices-utility_services_id")) {
            var mutility = document.getElementById("choices-utility_services_id");
            new Choices(mutility);
        }
    },
    
    methods: {
        async startRegistration() {
            this.resetApiValidation();
            try {
                this.consumption.month = this.month;
                await this.$store.dispatch("consumption/getConsumption", this.consumption);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Számítás befejezve!",
                    width: 500
                }); 
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setRealEstate(id) {
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == id
            });
            await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(id ? { realestate_id: id } : {}),
                    },
                });
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
        },
        isDisable(network) {
            for (let i = 0; i < network.get_meters.length; i++) {
                if(!network.get_meters[i].test){
                    return true;
                }
                for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
                    if(!network.get_meters[i].get_sub_meters[ii].test){
                        return true;
                    }
                }
            }
            return false;
        }
    }
}
</script>