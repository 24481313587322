import axios from 'axios';
import authHeader from './auth-header'; 
//import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
//const dataFormatter = new Jsona();

export default {
    async getConsumption(params) {
        const response = await axios.post(API_URL + "/consumption", { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page };
    },
}




