<template>
    <CModal
        size="lg"
        :visible="visibleTooltipsAndPopoversDemo"
        @close="closeModal()"
        aria-labelledby="TooltipsAndPopoverExample"
    >
        <CModalHeader>
            <CModalTitle id="TooltipsAndPopoverExample">
                <slot name="header"> Default Header </slot>
            </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <slot name="body"> Default body content </slot>
        </CModalBody>
        <CModalFooter>
            <slot name="footer"> Default footer content </slot>
        </CModalFooter>
    </CModal>
  </template>
  
  <script>
  import { CModal, CModalHeader, CModalBody, CButton } from '@coreui/vue-pro';
  
  
  export default {
    name: "Modal",
    components: {
        CModal,
        CModalHeader,
        CModalBody,
        CButton
    },
    props: {
        visibleTooltipsAndPopoversDemo: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
    },
  };
  </script>
  
  <style scoped lang="css">
    @import '../../node_modules/@coreui/coreui-pro/dist/css/coreui.min.css';
  </style>
  