/* eslint-disable no-unused-vars */
import areasService from '../services/areas.service';

const initialState = { areas: null, area: null };

export const areas = { 
    namespaced: true,
    state: initialState,
    actions: {
        async getAreas({ commit }, options) {
            const areas = await areasService.getAreas(options);
            commit('getAreasSuccess', areas);
        },

        async addArea({ commit }, newArea) {
            return await areasService.addArea(newArea);
        },

        async deleteArea({ commit }, areaId) {
            await areasService.deleteArea(areaId);
        },

        async getArea({ commit }, areaId) {
            const area = await areasService.getArea(areaId);
            commit('getAreaSuccess', area);
        },

        async editArea({ commit }, modifiedArea) {
            await areasService.editArea(modifiedArea); 
        },


    },
    mutations: {
        getAreasSuccess(state, areas) {
            state.areas = areas;
        },
        getAreaSuccess(state, area) {
            state.area = area;
        },
    },
    getters: {
        getAreasData(state) {
            return state.areas?.data;
        },
        getAreasMeta(state) {
            return state.areas?.meta;
        },
        getArea(state){
            return state.area;
        }
    }
}

