<template>
   <Toast />
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Ingatlan Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'RealEstates List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div id="wrapper" class="d-flex">
                                <div class="w-25" :style="`
                                        background-image: url(${getImage});
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        background-position: center;
                                        height: 300px;
                                `">
                            </div>
                            <div class="w-75">
                                <div class="row p-3">
                                    <div class="row p-3">
                                        <div class="col-5">
                                            <material-input placeholder="Név" id="name" v-model:value="realestate.name"
                                                name="name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.name"></validation-error>
                                        </div>

                                        <div class="col-5">
                                            <material-input placeholder="Helyrajzi Szám" id="lot_number" v-model:value="realestate.lot_number"
                                                name="lot_number" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Cím adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-countries" placeholder="Ország" v-model="realestate.country_code" name="choices-countries" class="form-control" disabled>
                                                <option value="">Válassz!</option>
                                                <option v-for="country in getcountryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="zip" v-model:value="realestate.zip" placeholder="Irányítószám"
                                                name="zip" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.zip"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="city" v-model:value="realestate.city" placeholder="Város"
                                                name="city" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.city"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="address" v-model:value="realestate.address" placeholder="Cím"
                                                name="address" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.address"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Tulajdonosi adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-owners" v-model.number="realestate.owner_id" name="choices-owners" class="form-control">
                                                <option value="">Válassz tulajdonost!</option>
                                                <option v-for="owner in getownerList" :key="owner.id" :value="owner.id" v-html="owner.name"></option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="ownership" v-model:value="realestate.ownership" placeholder="Tulajdoni hányad"
                                                name="ownership" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="deed_size" v-model:value="realestate.deed_size" placeholder="Tulajdoni lap szerinti alapterület (m2)"
                                            name="deed_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="gross_size" v-model:value="realestate.gross_size" placeholder="Ingatlan bruttó alapterülete (m2)"
                                            name="gross_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Egyéb</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <material-input id="building_nr" v-model:value="realestate.building_nr" placeholder="Épületek száma (db)"
                                                name="building_nr" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-sfunctions" v-model.number="realestate.realestate_functions_id" name="choices-sfunctions" class="form-control">
                                                <option value="">Válassz funkciót!</option>
                                                <option v-for="sfunction in getfunctionList" :key="sfunction.id" :value="sfunction.id">{{ sfunction.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-handlings" v-model.number="realestate.realestate_handlings_id" name="choices-handlings" class="form-control">
                                                <option value="">Válassz kezelési formát!</option>
                                                <option v-for="handling in gethandlingList" :key="handling.id" :value="handling.id">{{ handling.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_name" v-model:value="realestate.property_manager_name" placeholder="Ingatlankezelő neve"
                                                name="property_manager_name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone" placeholder="Ingatlankezelő telefonszáma"
                                                name="property_manager_phone" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_email" v-model:value="realestate.property_manager_email" placeholder="Ingatlankezelő e-mail címe"
                                                name="property_manager_email" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                                        </div>
                                    </div>

                                    <div class="row mt-1 mb-5 d-flex align-items-center">
                                        <div class="col-2 text-star">
                                            <Button type="button" label="Mentés" icon="pi pi-save" :loading="loading" @click.prevent="handleEdit" />
                                        </div>
                                    </div>
                                
                            </div>
                            </div>
                        </div>
                        <div class="relative">
                            <div class="w-100">
                                <hr><h2>Helységek hozzáadása</h2>
                            </div>
                            
                            <!--<div class="button_plus" id="showButton" color="dark" variant="ghost" @click="visible = !visible" />-->
                            <CCollapse :visible="visible" style="position: relative; top: 20px;">
                                <CCard class="mt-3">
                                <CCardBody>
                                    <div>
                                    <div class="table-responsive">
                                        <Toolbar class="mb-6">
                                            <template #start>
                                                <Button label="Helyiség Hozzáadás" icon="pi pi-plus" class="mr-2" @click="openNew" />
                                                <Button label="Átalakítás" icon="pi pi-building" severity="danger" outlined @click="" :disabled="!selectedareas || !selectedareas.length" />
                                            </template>

                                            <template #end>
                                                <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" customUpload chooseLabel="Import" class="mr-2" auto :chooseButtonProps="{ severity: 'secondary' }" />
                                                <Button label="Export" icon="pi pi-upload" severity="secondary" @click="exportCSV($event)" />
                                            </template>
                                        </Toolbar>
                                        <!-- Table-->
                                        <DataTable 
                                            ref="dt"
                                            v-model:selection="selectedareas"
                                            :value="areas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="10"
                                            :filters="filters"
                                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                            :rowsPerPageOptions="[5, 10, 25, 50]"
                                            currentPageReportTemplate="{first} - {last} a {totalRecords} helységből"
                                            resizableColumns 
                                            columnResizeMode="fit"
                                        >

                                        <template #header>
                                            <div class="flex flex-wrap gap-2 items-center justify-between">
                                                <div class="row mt-1 mb-1 d-flex align-items-right">
                                                    <IconField style="padding-left: 0px;">
                                                            <InputIcon>
                                                                <i class="pi pi-search" />
                                                            </InputIcon>
                                                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                                                    </IconField>
                                                </div>
                                                <div class="row mt-1 d-flex align-items-left">
                                                    <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                                            display="chip" placeholder="Select Columns" />
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                                        <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" sortable >
                                            <template v-if="col.field == 'area_functions_id'" #body="slotProps">
                                                <div>
                                                    {{slotProps.data.area_function[0].name}}
                                                </div>
                                            </template>
                                            <template v-if="col.field == 'area_positions_id'" #body="slotProps">
                                                <div>
                                                    {{slotProps.data.area_position[0].name}}
                                                </div>
                                            </template>
                                            <template v-if="col.field == 'area_floor_coverings_id'" #body="slotProps">
                                                <div>
                                                    {{slotProps.data.area_floor_covering[0].name}}
                                                </div>
                                            </template>
                                        </Column>
                                        <Column :exportable="false" style="min-width: 12rem">
                                            <template #body="slotProps">
                                                <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editarea(slotProps.data)" />
                                                <Button icon="pi pi-trash" outlined rounded severity="danger" @click="confirmDeletearea(slotProps.data)" />
                                            </template>
                                        </Column>
                                        </DataTable>
                                    </div>

                                    <Dialog v-model:visible="areaDialog" :style="{ width: '450px' }" header="Helység adatok" :modal="true">
                                        <div class="flex flex-col" style="display: flex;flex-direction: column; gap: 1rem;">
                                            <div>
                                                <label for="building_nr" class="block font-bold mb-3" style="display: block; font-weight: 700;">Épület szám</label>
                                                <InputText id="building_nr" v-model.trim="area.building_nr" required="true" autofocus :invalid="submitted && !area.building_nr" fluid />
                                                <small v-if="submitted && !area.building_nr" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="description" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helység szám</label>
                                                <InputText id="area_nr" v-model.trim="area.area_nr" required="true" autofocus :invalid="submitted && !area.area_nr" fluid />
                                                <small v-if="submitted && !area.area_nr" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="area_functions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Funkció</label>
                                                <Select id="area_functions_id" v-model="computedFunctionId" :options="getAreaFunctionsList" optionLabel="name" optionValue="id" placeholder="Select a Status" fluid></Select>
                                            </div>
                                            <div>
                                                <label for="area_positions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Pozíció</label>
                                                <Select id="area_positions_id" v-model="computedPositionId" :options="getAreaPositionsList" optionLabel="name" optionValue="id" placeholder="Select a Status" fluid></Select>
                                            </div>
                                            <div>
                                                <label for="description" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helyrajzi szám</label>
                                                <InputText id="lot_number" v-model.trim="area.lot_number" required="true" autofocus :invalid="submitted && !area.lot_number" fluid />
                                                <small v-if="submitted && !area.lot_number" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="description" class="block font-bold mb-3" style="display: block; font-weight: 700;">Nettó terület</label>
                                                <InputText id="net_floor_space" v-model.trim="area.net_floor_space" required="true" autofocus :invalid="submitted && !area.net_floor_space" fluid />
                                                <small v-if="submitted && !area.net_floor_space" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="description" class="block font-bold mb-3" style="display: block; font-weight: 700;">Bruttó terület</label>
                                                <InputText id="gross_floor_space" v-model.trim="area.gross_floor_space" required="true" autofocus :invalid="submitted && !area.gross_floor_space" fluid />
                                                <small v-if="submitted && !area.gross_floor_space" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="description" class="block font-bold mb-3" style="display: block; font-weight: 700;">Magasság</label>
                                                <InputText id="height" v-model.trim="area.height" required="true" autofocus :invalid="submitted && !area.height" fluid />
                                                <small v-if="submitted && !area.height" class="text-red-500">Name is required.</small>
                                            </div>
                                            <div>
                                                <label for="area_floor_coverings_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Burkolat</label>
                                                <Select id="area_floor_coverings_id" v-model="computedFloorCoveringsId" :options="getAreaFloorCoveringsList" optionLabel="name" optionValue="id" placeholder="Select a Status" fluid></Select>
                                            </div>
                                        </div>

                                        <template #footer>
                                            <Button label="Mégse" icon="pi pi-times" text @click="hideDialog" />
                                            <Button label="Mentés" icon="pi pi-check" :loading="loading" @click="savearea" />
                                        </template>
                                    </Dialog>

                                    <Dialog v-model:visible="deleteareaDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                                        <div class="flex items-center gap-4">
                                            <i class="pi pi-exclamation-triangle !text-3xl" />
                                            <span v-if="area"
                                                >Biztos vagy benne, hogy törlöd a <b>{{ area.area_nr }}</b
                                                > helységet?</span
                                            >
                                        </div>
                                        <template #footer>
                                            <Button label="No" icon="pi pi-times" text @click="deleteareaDialog = false" />
                                            <Button label="Yes" icon="pi pi-check" @click="deletearea" />
                                        </template>
                                    </Dialog>

                                    <Dialog v-model:visible="deleteareasDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                                        <div class="flex items-center gap-4">
                                            <i class="pi pi-exclamation-triangle !text-3xl" />
                                            <span v-if="area">Biztos vagy benne, hogy törlöd a kijelölt helységeket?</span>
                                        </div>
                                        <template #footer>
                                            <Button label="No" icon="pi pi-times" text @click="deleteareasDialog = false" />
                                            <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedareas" />
                                        </template>
                                    </Dialog>
                                </div>
                                </CCardBody>
                                </CCard>
                            </CCollapse>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import { CButton, CCollapse, CCard, CCardBody } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import Modal from "@/components/Modal";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import { FilterMatchMode } from '@primevue/core/api';      
import _ from "lodash"

export default {
    name: 'EditRealEstate',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        CButton,
        CCollapse,
        CCard,
        CCardBody,
        Modal,
    },
    mixins: [formMixin],
    setup() {
        const visible = ref(true);
        return {
            visible,
            color: '#33e8f5'
        }
    },
    data() {
        return {
            data: [],
            realestate: {},
            areas: [],
            countries: {},
            owners: {},
            sfunctions: {},
            handlings: {},
            newArea: {},
            selected: null,
            areaDialog: false,
            deleteareaDialog: false,
            deleteareasDialog: false,
            area: {},
            selectedareas: null,
            filters: {},
            submitted: false,
            loading: false,
            selectedColumns: null,
            columns: null,
        }
    },
    created() {
        this.initFilters();
        this.columns = [
            {field: 'building_nr', header: 'Épület'},
            {field: 'area_nr', header: 'Helység'},
            {field: 'area_functions_id', header: 'Funkció'},
            {field: 'area_positions_id', header: 'Pozíció'},
            {field: 'lot_number', header: 'Helyrajzi szám'},
            {field: 'net_floor_space', header: 'Nettó terület'},
            {field: 'gross_floor_space', header: 'Bruttó terület'},
            {field: 'height', header: 'Magasság'},
            {field: 'area_floor_coverings_id', header: 'Burkolat'},
        ];
        this.selectedColumns = this.columns;
    },
    computed: {
        getcountryList() {
            return this.$store.getters["countries/getCountriesData"];
        },
        getownerList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getfunctionList() {
            return this.$store.getters["sfunctions/getFunctionsData"];
        },
        gethandlingList() {
            return this.$store.getters["handlings/getHandlingsData"];
        },
        getAreaPositionsList() {
            return this.$store.getters["positions/getPositionsData"];
        },
        getAreaFunctionsList() {
            return this.$store.getters["afunctions/getFunctionsData"];
        },
        getAreaFloorCoveringsList() {
            return this.$store.getters["coverings/getCoveringsData"];
        },
        getRealestateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getImage() {
            if (!this.profile_image) 
                return require("@/assets/img/haz_rajz.jpg");
            else {
                return this.profile_image;
            }
        },
        computedFunctionId: {
            get() {
                if (this.area.area_functions_id) { 
                    return this.area.area_functions_id.toString()
                }
            },
            set(val) {
                this.area.area_functions_id = Number(val)
            }
        },
        computedPositionId: {
            get() {
                if (this.area.area_positions_id) { 
                    return this.area.area_positions_id.toString()
                }
            },
            set(val) {
                this.area.area_positions_id = Number(val)
            }
        },
        computedFloorCoveringsId: {
            get() {
                if (this.area.area_floor_coverings_id) { 
                    return this.area.area_floor_coverings_id.toString()
                }
            },
            set(val) {
                this.area.area_floor_coverings_id = Number(val)
            }
        },
    },
    async mounted() {
        await this.$store.dispatch("countries/getCountries");
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("sfunctions/getFunctions");
        await this.$store.dispatch("handlings/getHandlings");
        await this.$store.dispatch("positions/getPositions");
        await this.$store.dispatch("afunctions/getFunctions");
        await this.$store.dispatch("coverings/getCoverings");
    
        try {
            await this.$store.dispatch("realestates/getRealEstate", this.$route.params.id);
            this.realestate = this.$store.getters['realestates/getRealEstate'];
            this.realestate = this.realestate[0];
            this.realestate.id = this.$route.params.id;
            if (this.realestate.areas.length > 0) {
                this.realestate.areas.forEach((owner, index) => {
                    this.areas.push({
                        id: owner.id,
                        building_nr: owner.building_nr,
                        area_nr: owner.area_nr,
                        lot_number: owner.lot_number,
                        area_positions_id: owner.area_positions_id,
                        area_position: this.getAreaPositionsList.filter(val => val.id == owner.area_positions_id),
                        area_functions_id: owner.area_functions_id,
                        area_function: this.getAreaFunctionsList.filter(val => val.id == owner.area_functions_id),
                        net_floor_space: owner.net_floor_space,
                        gross_floor_space: owner.gross_floor_space,
                        height: owner.height,
                        area_floor_coverings_id: owner.area_floor_coverings_id,
                        area_floor_covering: this.getAreaFloorCoveringsList.filter(val => val.id == owner.area_floor_coverings_id),
                    });
                });
            }
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: error,
                width: 500
            });
        }
        if (document.getElementById("choices-countries")) {
            var countries = document.getElementById("choices-countries");
            this.countries = new Choices(countries);
            this.countries.setChoiceByValue(this.realestate.country_code);
        }

        if (document.getElementById("choices-owners")) {
            var owners = document.getElementById("choices-owners");
            this.owners = new Choices(owners);
            this.owners.setChoiceByValue(String(this.realestate.owner_id));
        }

        if (document.getElementById("choices-sfunctions")) {
            var sfunctions = document.getElementById("choices-sfunctions");
            this.sfunctions = new Choices(sfunctions);
            this.sfunctions.setChoiceByValue(String(this.realestate.realestate_functions_id));
        }

        if (document.getElementById("choices-handlings")) {
            var handlings = document.getElementById("choices-handlings");
            this.handlings = new Choices(handlings);
            this.handlings.setChoiceByValue(String(this.realestate.realestate_handlings_id));
        }
    },
    methods: {
        formatCurrency(value) {
            if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
        },
        openNew() {
            this.area = {};
            this.submitted = false;
            this.areaDialog = true;
        },
        hideDialog() {
            this.areaDialog = false;
            this.submitted = false;
        },
        async savearea() {
            this.loading = true
            this.submitted = true;

			if (this.area?.area_nr?.trim()) {
                if (this.area.id) {
                    this.area.id = this.area.id.toString();
                    this.area.realestate_id = this.realestate.id;
                    try {
                        await this.$store.dispatch("areas/editArea", this.area);

                        this.area.area_position = this.getAreaPositionsList.filter(val => val.id == this.area.area_positions_id);
                        this.area.area_function = this.getAreaFunctionsList.filter(val => val.id == this.area.area_functions_id);
                        this.area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == this.area.area_floor_coverings_id);

                        this.areas[this.findIndexById(this.area.id)] = this.area;
                        this.$toast.add({severity:'success', summary: 'Sikeres', detail: 'Helység módosítva!', life: 3000});
                    }
                    catch (error) {
                        this.$toast.add({severity:'error', summary: 'HIBA', detail: error, life: 10000});
                        this.areaDialog = false;
                        this.area = {};
                        this.loading = false
                    }
                }
                else {
                    this.area.realestate_id = this.realestate.id;
                    try {
                        const areaID = await this.$store.dispatch("areas/addArea", this.area);
                        this.area.id = areaID.toString();

                        this.area.area_position = this.getAreaPositionsList.filter(val => val.id == this.area.area_positions_id);
                        this.area.area_function = this.getAreaFunctionsList.filter(val => val.id == this.area.area_functions_id);
                        this.area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == this.area.area_floor_coverings_id);

                        this.areas.push(this.area);
                        this.$toast.add({severity:'success', summary: 'Sikeres', detail: 'Helység hozzáadva!', life: 3000});
                    }
                    catch (error) {
                        this.$toast.add({severity:'error', summary: 'HIBA', detail: error, life: 10000});
                        this.areaDialog = false;
                        this.area = {};
                        this.loading = false
                    }
                }

                this.areaDialog = false;
                this.area = {};
                this.loading = false
            }
        },
        editarea(area) {
            this.area = {...area};
            this.areaDialog = true;
        },
        confirmDeletearea(area) {
            this.area = area;
            this.deleteareaDialog = true;
        },
        async deletearea() {
            this.areas = this.areas.filter(val => val.id !== this.area.id);
            this.deleteareaDialog = false;
            await this.$store.dispatch("areas/deleteArea", this.area.id);
            this.area = {};
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'area Deleted', life: 3000});
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.areas.length; i++) {
                if (this.areas[i].id == id) {
                    index = i;
                    break;
                }
            }

            return index;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteareasDialog = true;
        },
        deleteSelectedareas() {
            this.areas = this.areas.filter(val => !this.selectedareas.includes(val));
            this.deleteareasDialog = false;
            this.selectedareas = null;
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'areas Deleted', life: 3000});
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        async handleEdit() {
            this.loading = true
            this.resetApiValidation();
            try {
                this.realestate.deed_size = parseFloat(this.realestate.deed_size);
                this.realestate.gross_size = parseFloat(this.realestate.gross_size);
                this.realestate.areas = this.areas;
                await this.$store.dispatch("realestates/editRealEstate", this.realestate);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ingatlan sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "RealEstates List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
            this.loading = false
        },
        addArea(type, value) {
            if (type == 'commissioning_date') {
                this.newArea[type] = this.format(value);
            } 
            else if(type == 'area_functions_id' || type == 'area_positions_id' || type == 'area_floor_coverings_id') {
                this.newArea[type] = Number(value);
            }
            else if(type == 'net_floor_space' || type == 'gross_floor_space' || type == 'height') {
                this.newArea[type] = parseFloat(value);
            }
            else {
                this.newArea[type] = value;
            }
            console.log(this.newArea);
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        }
    }
}
</script>