<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Bejövő Számlák</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'Add InInvoice' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Számla Felvitele
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="px-0 pb-0 card-body">
            
            <div class="table-responsive p-4">
            <!-- Table -->
            <DataTable id="dataTable" :data="data" :columns="columns" ref="table" class="table table-flush dataTable-table" width="100%">
              <template #action="props">
                <td
                    class = "dt-control"
                    :text="`Col 1: ${props.cellData}`"
                    @click="rowClick"
                ></td>
              </template>
            </DataTable>
          </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
//import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

DataTable.use(DataTablesCore);

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

var sortDirection = "created_at";
var getCurrentPage = 1;
var searchQuery = "";

const getIncomingInvoicesList = _.debounce(async function (params) {
  await store.dispatch("incominginvoices/getIncomingInvoices", {
    ...(params.sort ? { sort: params.sort } : {}),
    filter: {
      ...(params.query ? { name: params.query } : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "IncomingInvoiceList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  data() {
    return {
      search: "",
      dataIncomingInvoices: [],
      pagination: {},
      dataTable: null,
      keys: null,
    };
  },
  computed: {
    incominginvoicesList() {
      return this.$store.getters["incominginvoices/getIncomingInvoicesData"];
    },
    metaPage() {
      return this.$store.getters["incominginvoices/getIncomingInvoicesMeta"];
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    incominginvoicesList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch",
    },
  },
  async mounted() {
    if (this.$refs.table) {
      this.dataTable = new DataTable(this.$refs.table, {
        searchable: false,
        fixedHeight: false,
        perPage: 5,
      });

      document.querySelector(".dataTable-bottom").remove();
      this.dataTable.label = null;
      this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

      await getIncomingInvoicesList({
        sort: sortDirection,
        query: "",
        nr: getCurrentPage,
        perpage: this.dataTable.options.perPage,
      });

      this.dataTable.on("datatable.perpage", async function (perpage) {
        this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
        await getIncomingInvoicesList({
          sort: sortDirection,
          query: searchQuery,
          nr: (getCurrentPage = 1),
          perpage: perpage,
        });
      });

      this.dataTable.on("datatable.sort", async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getIncomingInvoicesList({
          sort: (sortDirection = direction + column),
          query: searchQuery,
          nr: getCurrentPage,
          perpage: this.options.perPage,
        });
      });
    }
  },
  beforeUnmount() {
    sortDirection = "created_at";
    searchQuery = "";
    getCurrentPage = 1;
  },
  methods: {
    async getDataFromPage(page) {
      await getIncomingInvoicesList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      await getIncomingInvoicesList({
        sort: sortDirection,
        query: this.search,
        nr: (getCurrentPage = 1),
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
      this.keys = Object.keys(this.pagination);

      this.pagination = {
        currentPage: this.pagination[this.keys[0]],
        perPage: this.pagination[this.keys[3]],
        total: this.pagination[this.keys[5]],
      };
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataIncomingInvoices = [];
      if (this.incominginvoicesList.length > 0) {
        this.incominginvoicesList.forEach((incominginvoice) => {
          this.dataIncomingInvoices.push([
          incominginvoice.invoice_number,
          incominginvoice.meter_id,
          
            `<div class="text-end">${
              this.actionEditButton(incominginvoice.id, "Főmérő módosítása") +
              this.actionDeleteButton(incominginvoice.id, "Főmérő törlése")
            }</div>`,
          ]);
        });
        this.dataTable.data = [];
        this.dataTable.refresh();
        this.dataTable.insert({ data: this.dataIncomingInvoices });
        this.removeEvent();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit InInvoice",
          deletePath: "incominginvoices/deleteIncomingInvoice",
          getPath: "incominginvoices/getIncomingInvoices",
          textDelete: "Főmrő sikeresen törölve!",
          textDefaultData: "incominginvoices",
        });
      } else {
        this.dataTable.setMessage("Nincs a keresési feltételnek megfelelő találat.");
      }
    },
  },
};
</script>

<style scoped lang="css">
  @import 'datatables.net-bs5';
</style>
