import axios from 'axios';
import authHeader from './auth-header'; 
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getSubMeters(params) {
        const response = await axios.get(API_URL + "/submeters", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addSubMeter(submeter) {
        submeter.type = "submeters";
        const newJson = dataFormatter.serialize({ stuff: submeter });
        const response = await axios.post(API_URL + "/addsubmeter", newJson, { headers: authHeader() });
        return response.data;
    },

    async deleteSubMeter(submeterId) {
        await axios.delete(API_URL + "/submeters/" + submeterId, { headers: authHeader() });
    },

    async getSubMeter(submeterId) {
        const response = await axios.get(API_URL + "/submeters/" + submeterId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editSubMeter(submeter) {
        submeter.type = "submeters"
        const newJson = dataFormatter.serialize({ stuff: submeter })
        const response = await axios.patch(API_URL + "/editsubmeter/" + submeter.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




