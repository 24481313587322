<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-7">
                                <h5 class="mb-0">Bejövő Számla Felvitele</h5>
                            </div>
                            
                            <div class="col-3  ">
                                <material-button class="float-left btn btm-sm" 
                                    @click.prevent="handleAdd">
                                    Számla Hozzáadása
                                </material-button>
                            </div>
                            <div class="col-2 text-end ">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'InInvoice List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row ">

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Mérési pont azonosító</p>
                                </div>
                                <div class="col-5">
                                    <material-input id="utility_networks_pod" v-model:value="invoice.utility_networks_pod" 
                                        name="utility_networks_pod" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.utility_networks_pod"></validation-error>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Szolgáltatás típusa</p>
                                </div>
                                <div class="col-3">
                                    <select id="choices-utility_services_id" v-model.number="invoice.utility_services_id"  name="choices-utility_services_id" class="form-control">
                                        <option v-for="sutility in getutilityList" :key="sutility.id" :value="sutility.id">{{ sutility.name }}</option>
                                    </select>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Elszámolási időszak</p>
                                </div>
                                <div class="col-5">
                                    <material-input id="consumption" v-model:value="invoice.consumption"
                                        name="consumption" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.consumption"></validation-error>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla sorszáma</p>
                                </div>
                                <div class="col-3">
                                    <material-input id="invoice_number" v-model:value="invoice.invoice_number" 
                                        name="invoice_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.invoice_number"></validation-error>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Kezdő és záró mérőállás</p>
                                </div>
                                <div class="col-5">
                                    <material-input id="consumption" v-model:value="invoice.consumption"
                                        name="consumption" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.consumption"></validation-error>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla kelte</p>
                                </div>
                                <div class="col-3">
                                    <material-input id="invoice_date" v-model:value="invoice.invoice_date" 
                                        name="invoice_date" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.invoice_date"></validation-error>
                                </div>
                            </div>
                                <div class="row border border-secondary rounded my-5">
                                   
                                        <div class="col-2 row-span-4">
                                            <p class="form-label text-black pt-2">Logo</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 text-bold">E.ON Energiaszolgáltató Kft.</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 text-bold">Számla összeg</p>
                                        </div>
                                        <div class="col-2">
                                            
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 text-bold">Szerződés száma</p>
                                        </div>
                                        <div class="col-3">
                                            <p class="form-label text-black pt-2">13958147-2-11</p>
                                        </div>
                                        <div class="col-5 row-span-2">
                                            <p class="form-label text-black pt-2 text-bold">913 675</p>
                                        </div>
                                        <div class="col-2">
                                            
                                        </div>
                                        <div class="col-2 ">
                                            <p class="form-label text-black pt-2 text-bold">Bankszámlaszám</p>
                                        </div>
                                        <div class="col-3">
                                            <p class="form-label text-black pt-2">10918001-00000024-94090033</p>
                                        </div>
                                        <div class="col-5">
                                            
                                        </div>
                                        <div class="col-2">
                                            
                                        </div>
                                        <div class="col-2 ">
                                            <p class="form-label text-black pt-2 text-bold">Kapcsolattartó</p>
                                        </div>
                                        <div class="col-3">
                                            <p class="form-label text-black pt-2"> Póczak Judit</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 text-bold">HUF</p>
                                        </div>
                                    
                                </div>  
                                <div class="row ">
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Főmérő gyári száma</p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">23123123312</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztó neve</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">SZTK - Project Kft.</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Aktuális fogyasztás </p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">1 100 kWh</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Partnerszám</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">1000000352</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Aktuális visszatáplálás</p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">0 kWh</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztási hely neve</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">Uránia Üzletház</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Szerződés lejárati idő </p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">2024.12.12</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztási hely címe</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">9700 Szombathely Uránia udvar 4.</p>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 text-bold">Tétel megnevezése</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 text-bold">ÁFA%</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Nettó érték (HUF)</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Áfa összege(HUF)</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Bruttó érték (HUF)</p>
                                    </div>

                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Energiadíj összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="energy_price_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="energy_price_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="energy_price" v-model:value="invoice.energy_price" 
                                            name="energy_price" variant="static" 
                                            v-on:change="printOutAFAValue('energy_price_AFA_out','energy_price'),printOutNetValue('energy_price_Net_out','energy_price'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.energy_price"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Jövedéki adó összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="excise_tax_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="excise_tax_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="excise_tax" v-model:value="invoice.excise_tax" 
                                            name="excise_tax" variant="static" 
                                            v-on:change="printOutAFAValue('excise_tax_AFA_out','excise_tax'),printOutNetValue('excise_tax_Net_out','excise_tax'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.excise_tax"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Pénzeszközök összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="cash_equivalents_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">- </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="cash_equivalents" v-model:value="invoice.cash_equivalents" 
                                            name="cash_equivalents" variant="static" 
                                            v-on:change="printOut('cash_equivalents_out','cash_equivalents'),sumWithoutAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.cash_equivalents"></validation-error>
                                    </div>
                                    
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">KÁT és Prémium pénzeszközök összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="KAT_and_premium_equivalents_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">- </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="KAT_and_premium_equivalents" v-model:value="invoice.KAT_and_premium_equivalents" 
                                            name="KAT_and_premium_equivalents" variant="static" 
                                            v-on:change="printOut('KAT_and_premium_equivalents_out','KAT_and_premium_equivalents'),sumWithoutAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.KAT_and_premium_equivalents"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Rendszerhasználati díjak összesen </p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="grid_access_fees_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="grid_access_fees_AFA_out">  </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="grid_access_fees" v-model:value="invoice.grid_access_fees" 
                                            name="grid_access_fees" variant="static" 
                                            v-on:change="printOutAFAValue('grid_access_fees_AFA_out','grid_access_fees'),printOutNetValue('grid_access_fees_Net_out','grid_access_fees'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.grid_access_fees"></validation-error>
                                    </div>

                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_with_afa_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="sum_with_afa_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_with_afa"></p>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_without_afa"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">-</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_without_afa2"></p>
                                    </div>
                                    
                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Számla összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 "></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="gross_amount_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="gross_amount_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="gross_amount"></p>
                                    </div>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'AddInInvoice',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month
        }
    },
    data() {
        return {
            invoice: {},
            date: null,
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getMeterList() {
            return this.$store.getters["meters/getMetersData"];
        },
        getSubMeterList() {
            return this.$store.getters["submeters/getSubMetersData"];
        }
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("meters/getMeters");
        await this.$store.dispatch("submeters/getSubMeters");

        if (document.getElementById("choices-utility_services_id")) {
            var mutility = document.getElementById("choices-utility_services_id");
            new Choices(mutility);
        }
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                this.invoice.month = this.month.month;
                this.invoice.year = this.month.year;
                await this.$store.dispatch("incominginvoices/addIncomingInvoice", this.invoice);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Számla sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "InInvoice List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        printOut(output,input){
            document.getElementById(output).innerHTML=document.getElementById(input).value;
        },
        printOutAFAValue(output,input){
            document.getElementById(output).innerHTML=Math.round(~~document.getElementById(input).value-~~document.getElementById(input).value/1.27);
        },
        printOutNetValue(output,input){
            document.getElementById(output).innerHTML=Math.round(~~document.getElementById(input).value/1.27);
        },
        sumWithAFA(){
            var calculate=~~document.getElementById("grid_access_fees").value+~~document.getElementById("energy_price").value+~~document.getElementById("excise_tax").value;
            var sumWithAfa=Math.round(calculate-calculate/1.27);
            document.getElementById("sum_with_afa").innerHTML=calculate;
            document.getElementById("sum_with_afa_Net_out").innerHTML=Math.round(calculate/1.27);
            document.getElementById("sum_with_afa_AFA_out").innerHTML=sumWithAfa;
            document.getElementById("gross_amount_AFA_out").innerHTML=sumWithAfa;
        },
        sumWithoutAFA(){
            var out=~~document.getElementById("KAT_and_premium_equivalents").value+~~document.getElementById("cash_equivalents").value;
            document.getElementById("sum_without_afa").innerHTML=out;
            document.getElementById("sum_without_afa2").innerHTML=out;
        },
        sumTotal(){
            var calculate=~~document.getElementById("sum_without_afa").innerHTML+~~document.getElementById("sum_with_afa").innerHTML;
            
            document.getElementById("gross_amount").innerHTML=calculate;
            document.getElementById("gross_amount_Net_out").innerHTML=Math.round((~~document.getElementById("grid_access_fees").value+~~document.getElementById("energy_price").value+~~document.getElementById("excise_tax").value)/1.27)+~~document.getElementById("KAT_and_premium_equivalents").value+~~document.getElementById("cash_equivalents").value;
            
        }
    }
}


</script>